import React, { useState } from "react";
import { motion } from "framer-motion";
import "./ContactUs.css"; // Make sure to create and style this CSS file for custom styles

const Privacy = () => {
  return (
    <div>
      <div className="ml-[86px]">
        <motion.h2
          style={{ paddingBottom: "20px" }}
          className="section-title"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          PRIVACY POLICY
        </motion.h2>
      </div>
      <div className="privacy-section">
        <h2 className="section-title">COMING SOON</h2>
      </div>
    </div>
  );
};

export default Privacy;
