import "./header.css";
import { ReactComponent as HeaderLogo } from "assets/headerlogo.svg";
import { ReactComponent as IconMenu } from "assets/menu_icon_dark.svg";
import { useNavigate } from "react-router-dom";

const Header = (props) => {
  const navigate = useNavigate();

  return (
    <div className="header-container">
      <div>
        <IconMenu
          className="header-menu-icon"
          onClick={() => {
            props.toggleDrawer();
          }}
        />
      </div>

      <HeaderLogo
        className="header-logo cursor-pointer"
        onClick={() => {
          navigate("/");
        }}
      />
    </div>
  );
};

export default Header;
