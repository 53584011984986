import React, { useState } from "react";
import emailjs from "emailjs-com";
import "./ContactUs.css";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    user_name: "",
    user_phone: "",
    user_email: "",
    subject: "",
    message: "",
  });

  const [open, setOpen] = useState(false); // State to control modal visibility

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_dl9swcd", // Your service ID
        "template_j9kff9f", // Your template ID
        e.target, // The form data
        "i6u0wWhzDDzj8hTOo" // Your API key
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setOpen(true); // Open the modal on successful form submission
        },
        (error) => {
          console.log("FAILED...", error);
          alert("Failed to send your message. Please try again.");
        }
      );

    // Reset form data
    setFormData({
      user_name: "",
      user_phone: "",
      user_email: "",
      subject: "",
      message: "",
    });
  };

  const handleClose = () => {
    setOpen(false); // Close the modal
  };

  return (
    <div className="contact-us-section">
      <div className="about-us-content">
        <div className="row p-0">
          <div
            data-aos="fade-right"
            data-aos-offset="300"
            data-aos-duration="2000"
            data-aos-easing="ease-in-sine"
            className="col-lg-10 mb-4"
          >
            <h2 className="section-title">CONTACT US</h2>
            <h2
              className="dss-fw-400 dv-fs-70 contact-heading"
              style={{ letterSpacing: "-5.9px", color: "#203468" }}
            >
              WE WANT TO <span className="text-primary">HEAR</span>
              <br /> FROM <span className="text-primary">YOU</span>.
            </h2>
          </div>
        </div>

        <div className="row p-0">
          <div className="col-lg-4">
            {/* Contact info section */}
            <p className="dss-fw-700" style={{ fontSize: "14.5px" }}>
              Reach us to
            </p>
            <div className="contact-info mt-4">
              <a href="mailto:info@Dovehousecapital.com">
                <p>
                  <strong>
                    <img
                      src="/images/icons/Email.svg"
                      className="social-icons"
                    />
                  </strong>
                  info@dovehousecapital.com
                </p>
              </a>
              {/* <p>
                <img src="/images/icons/Address.svg" />
                Al Falah St - Al Maryah Island - Abu Dhabi Global Market Square
              </p> */}
              <div className="social-media mt-3">
                <p className="dss-fw-700">Social Media</p>
                <div className="icons-social-media">
                  <a
                    href="https://www.linkedin.com/company/Dovehouse-capital/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/images/icons/LinkedIn.svg"
                      className="social-icons-contact"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            {/* Inquiry form section */}
            <div className="inquiry-form-section pb-5">
              <div className="text-left form-header mb-4">
                <h2>SEND</h2>
                <h2 className="text-primary">INQUIRY</h2>
              </div>
              <form id="contact-form" onSubmit={handleSubmit}>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control mb-3"
                    name="user_name"
                    placeholder="Name"
                    value={formData.user_name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control mb-3"
                    name="user_phone"
                    placeholder="Phone"
                    value={formData.user_phone}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control mb-3"
                    name="user_email"
                    placeholder="Email"
                    value={formData.user_email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control mb-3"
                    name="subject"
                    placeholder="Subject"
                    value={formData.subject}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control mb-3"
                    name="message"
                    rows="3"
                    placeholder="Message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>
                <div className="btn-section">
                  <button
                    type="submit"
                    className="btn btn-outline-primary btn-block form-button"
                  >
                    SEND
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Success Modal */}
      <Dialog className="popup-close-box" open={open} onClose={handleClose}>
        <DialogTitle className="text-center">Thank You!</DialogTitle>
        <DialogContent className="text-center">
          Your inquiry has been received. Our team will get back to you soon.
          Thank you for choosing Dovehouse Capital.
        </DialogContent>
        <DialogActions className="close-btn-continer">
          <Button
            sx={{ justifyContent: "center" }}
            onClick={handleClose}
            color="primary"
            className="text-center btn-close-success"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ContactUs;
