import { useEffect, useState } from "react";
import imgprovenleadership from "../../assets/images/Proven-leadership.png";
import { motion } from "framer-motion"; // Import motion from framer-motion
import "./leadershipcss.css";

const LeadershipComponent = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Check if the screen width is less than or equal to 768px (mobile)
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Set the initial state
    handleResize();

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className="leadership-title-main">
        <motion.h2
          style={{ paddingBottom: "20px" }}
          className="section-title mt-4"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          LEADERSHIP
        </motion.h2>
      </div>
      <div className="leadership-component bg-light">
        <div className="about-us-content leadership-content">
          <div className="proven-leadership">
            {/* <div className="col-md-6">
              <img
                data-aos="zoom-in-right"
                data-aos-offset="300"
                data-aos-duration="2000"
                data-aos-easing="ease-in-sine"
                src={imgprovenleadership}
                alt="Leadership Team"
                className="img-fluid w-100 h-100"
              />
            </div> */}
            <div className="col-md-6 d-flex align-items-center">
              <div
                className="discription-proven-leadership"
                data-aos="fade-right"
                data-aos-offset="300"
                data-aos-duration="1000"
                data-aos-easing="ease-in-sine"
              >
                <h2 className="text-white mb-4 dfs-70 dv-md-fs-25">
                  PROVEN LEADERSHIP
                </h2>
                <p className="text-white">
                  Our leadership team brings unique expertise and insightful
                  track records from globally recognized companies across
                  multiple industries.
                </p>
                <br />
                <p className="text-white">
                  They have worked together for over three decades, including
                  the formative years to build Dovehouse Capital into an
                  industry-leading investment firm.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container-fluid bg-white py-5  mt-0 md:mt-5"
        data-aos={!isMobile ? "fade-up" : ""}
        data-aos-offset={!isMobile ? "300" : ""}
        data-aos-duration={!isMobile ? "200" : ""}
      >
        <div className="about-us-content leadership-content">
          <h2
            data-aos={!isMobile ? "fade-right" : ""}
            data-aos-offset={!isMobile ? "300" : ""}
            data-aos-duration={!isMobile ? "200" : ""}
            data-aos-easing={!isMobile ? "ease-in-sine" : ""}
            className="teams-heading mb-4 dv-fs-55 dss-fw-400 dv-md-fs-25"
            style={{
              color: "#203468",
              fontSize: "80px",
              letterSpacing: "-8px",
            }}
          >
            BUILDING EXCELLENCE:
            <br />
            <span className="dv-text-primary mb-4">Dovehouse CAPITAL </span>
            TEAM
          </h2>

          <div className="flex justify-end">
            <div className="card-top-dss">
              {" "}
              <p
                data-aos={!isMobile ? "fade-left " : ""}
                data-aos-offset="300"
                data-aos-duration="200"
                data-aos-easing={!isMobile ? "ease-in-sine" : ""}
                className="mb-5 ml-96"
                style={{ fontFamily: "Cera Pro" }}
              >
                The team is deeply experienced in attracting, mentoring and
                retaining exceptional talent, designing and implementing
                institutional-scale technology and analytics platforms, and
                building strong relationships with investment partners.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mb-4">
              <div
                className="card team-leadership"
                data-aos="zoom-in-right"
                data-aos-delay="600"
                data-aos-duration="500"
              >
                <div className="card-overlay">
                  <h4>CLAUS ROSENBERG GOTTHARD</h4>
                  <p>Danish, 1967</p>
                  <p
                    className="dv-text-primary"
                    style={{ fontFamily: "Cera Pro" }}
                  >
                    Co-Founder & CEO
                  </p>
                  <p style={{ fontFamily: "Cera Pro", fontSize: "13px" }}>
                    Serial-Entrepreneur & Investor, have established &gt;20
                    ventures from traditional FMCG Trading & Facility Services
                    to Renewable Energy & Technology/Software with 3 exits.
                    Experience in creating vision and building a team that pulls
                    together in the same direction.
                  </p>
                  <a
                    href="https://www.linkedin.com/in/clausrosenberg/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="icon-link"
                  >
                    <img
                      src="/images/icons/LinkedIn.svg"
                      alt="LinkedIn"
                      className="socail-icon"
                    />
                  </a>
                </div>
                <img
                  src="/images/tl-1.png"
                  alt="Claus Rosenberg Gotthard"
                  className="img-fluid mb-3"
                />
                <div className="flex justify-between">
                  <h4 className="dss-fw-400 dv-fs-25 dv-md-fs-25">
                    CLAUS ROSENBERG GOTTHARD
                  </h4>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="23"
                    viewBox="0 0 26 23"
                    fill="none"
                    style={{ display: isMobile ? "none" : "inline" }}
                  >
                    <path
                      d="M1 11.5H25M25 11.5L14.7143 1M25 11.5L14.7143 22"
                      stroke="#33B9EA"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <p>Danish, 1967</p>

                <p
                  className="dv-text-primary dss-fw-400"
                  style={{ fontFamily: "Cera Pro" }}
                >
                  Co-Founder & CEO
                </p>
                <div className="mobile-card-overlay">
                  <p style={{ fontFamily: "Cera Pro", fontSize: "13px" }}>
                    Serial-Entrepreneur & Investor, have established &gt;20
                    ventures from traditional FMCG Trading & Facility Services
                    to Renewable Energy & Technology/Software with 3 exits.
                    Experience in creating vision and building a team that pulls
                    together in the same direction.
                  </p>
                </div>
                <a
                  href="https://www.linkedin.com/in/clausrosenberg/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon-link"
                  height={23}
                >
                  <img
                    src="/images/icons/LinkedIn.svg"
                    alt="LinkedIn"
                    className="socail-icon"
                  />
                </a>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div
                className="card team-leadership"
                data-aos="zoom-in-left"
                data-aos-delay="800"
                data-aos-duration="500"
              >
                <div className="card-overlay">
                  <h4>MARTIN RASMUSSEN</h4>
                  <p>Danish, 1965</p>
                  <p
                    className="dv-text-primary"
                    style={{ fontFamily: "Cera Pro" }}
                  >
                    Co-Founder & CIO
                  </p>
                  <p>
                    Experienced trader, investor and manager with a full FCA
                    (UK) track record. Former UBS, Hamburg Commercial Bank, RBS
                    Greenwich, Cargill and AP Moller. Martin managed Eu24bn
                    during the Great Financial Crisis (2008) and effectively
                    navigated the markets to the benefit of his sponsor and
                    investor at the time. Several initiatives, ventures and
                    exits.
                  </p>
                  <a
                    href="https://www.linkedin.com/in/ciomrasmussen/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="icon-link"
                  >
                    <img
                      src="/images/icons/LinkedIn.svg"
                      alt="LinkedIn"
                      height={23}
                      width={23}
                      className="socail-icon"
                    />
                  </a>
                </div>
                <img
                  src="/images/mar.png"
                  alt="Martin Rasmussen"
                  className="img-fluid mb-3"
                />
                <div className="flex justify-between">
                  <h4 className="dss-fw-400 dv-fs-25 dv-md-fs-25">
                    MARTIN RASMUSSEN
                  </h4>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="23"
                    viewBox="0 0 26 23"
                    fill="none"
                    style={{ display: isMobile ? "none" : "inline" }}
                  >
                    <path
                      d="M1 11.5H25M25 11.5L14.7143 1M25 11.5L14.7143 22"
                      stroke="#33B9EA"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <p>Danish, 1965</p>

                <p
                  className="dv-text-primary dss-fw-400"
                  style={{ fontFamily: "Cera Pro" }}
                >
                  Co-Founder & CIO
                </p>
                <div className="mobile-card-overlay">
                  <p style={{ fontFamily: "Cera Pro", fontSize: "13px" }}>
                    Experienced trader, investor and manager with a full FCA
                    (UK) track record. Former UBS, Hamburg Commercial Bank, RBS
                    Greenwich, Cargill and AP Moller. Martin managed Eu24bn
                    during the Great Financial Crisis (2008) and effectively
                    navigated the markets to the benefit of his sponsor and
                    investor at the time. Several initiatives, ventures and
                    exits.
                  </p>
                </div>
                <a
                  href="https://www.linkedin.com/in/ciomrasmussen/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="icon-link"
                >
                  <img
                    src="/images/icons/LinkedIn.svg"
                    alt="LinkedIn"
                    height={23}
                    width={23}
                    className="socail-icon"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeadershipComponent;
