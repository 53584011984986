import React, { useState } from "react";
import { Tabs, Tab, Typography, Box, useMediaQuery } from "@mui/material";

import { ReactComponent as GroupsIcon } from "assets/Social.svg";
import { ReactComponent as PublicIcon } from "assets/Partner.svg";
import { ReactComponent as DiamondIcon } from "assets/Excellence.svg";
import { ReactComponent as HandshakeIcon } from "assets/Integrity.svg";
import { ReactComponent as MenuBookIcon } from "assets/Empowerment.svg";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="core-value-content-box mb-5"
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="box-corevalue-conetent-des" sx={{ p: 2 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

const Corevalue = () => {
  const [value, setValue] = useState(0);
  const isMobile = useMediaQuery("(max-width:600px)");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const coreValues = [
    {
      icon: <HandshakeIcon />,
      title: "INTEGRITY AND RESPECT",
      content:
        "We uphold the highest standards of integrity in all our actions. We treat our clients, partners, and team members with utmost respect, fostering trust and long-lasting relationships.",
    },
    {
      icon: <DiamondIcon />,
      title: "EXCELLENCE AND DEDICATION",
      content:
        "We strive for excellence in everything we do. Our dedication to providing top-notch services and innovative solutions sets us apart in the industry.",
    },
    {
      icon: <PublicIcon />,
      title: "PARTNERSHIP AND COLLABORATION",
      content:
        "Collaboration is key to our success. We view our relationships with clients as true partnerships, engaging openly and working together to achieve shared goals. Our collaborative spirit extends within our team, encouraging diverse perspectives and collective problem-solving.",
    },
    {
      icon: <MenuBookIcon />,
      title: "EMPOWERMENT THROUGH KNOWLEDGE",
      content:
        "We believe in the power of knowledge. We continuously educate ourselves and our clients, enabling informed decision-making and fostering growth.",
    },
    {
      icon: <GroupsIcon />,
      title: "SOCIAL RESPONSIBILITY",
      content:
        "We are committed to making a positive impact on society. Our business practices are guided by ethical considerations and a dedication to sustainable, responsible growth.",
    },
  ];

  return (
    <Box
      className="core-value-container"
      sx={{ width: "100%", bgcolor: "background.paper" }}
    >
      <Typography
        variant="h3"
        component="h1"
        align="left"
        className="main-title heading-title-invesment"
        sx={{
          mt: 4,
          mb: 4,
          ml: "4",
          pl: "4",
          letterSpacing: "-4px",
          color: "#203569",
        }}
      >
        CORE VALUES OF
        <br />
        <span style={{ color: "#03a9f4" }}>Dovehouse CAPITAL</span>
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          height: isMobile ? "auto" : 450,
        }}
      >
        {isMobile ? (
          <Box className="corevalue-content-box" sx={{ flexGrow: 1 }}>
            <div className="core-value-box">
              {coreValues.map((coreValue, index) => (
                <TabPanel key={index} value={index} index={index}>
                  <div className="tab-icon-core-value">{coreValue.icon}</div>
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{ color: "#1a237e" }}
                  >
                    {coreValue.title}
                  </Typography>
                  <Typography paragraph>{coreValue.content}</Typography>
                </TabPanel>
              ))}
            </div>
          </Box>
        ) : (
          <>
            <Tabs
              orientation="vertical"
              className="tabs-corevalue"
              variant="standard"
              value={value}
              onChange={handleChange}
              aria-label="Core Values tabs"
              sx={{
                borderRight: 1,
                borderColor: "white",
                minWidth: 200,
              }}
            >
              {coreValues.map((coreValue, index) => (
                <Tab
                  key={index}
                  className="tab-corevalue-list"
                  icon={coreValue.icon}
                  label={
                    <span className="tab-lebel-text">
                      {coreValue.title} &nbsp; &nbsp;&nbsp;{" "}
                    </span>
                  }
                />
              ))}
            </Tabs>
            <Box className="corevalue-content-box" sx={{ flexGrow: 1 }}>
              <div className="core-value-box">
                {coreValues.map((coreValue, index) => (
                  <TabPanel key={index} value={value} index={index}>
                    <div className="tab-icon-core-value">{coreValue.icon}</div>
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{ color: "#1a237e" }}
                    >
                      {coreValue.title}
                    </Typography>
                    <Typography paragraph>{coreValue.content}</Typography>
                  </TabPanel>
                ))}
              </div>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Corevalue;
