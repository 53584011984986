// import { ReactComponent as Intro } from 'assets/intro_bg.svg';
import { ReactComponent as Logo } from "assets/logo_white.svg";

import React from "react";
import "./footer.css";
function HomeFooter() {
  return (
    <div className="footer-container h-full">
      <div className="h-[30%] bg-white"></div>
      <div className="h-[49%]">
        <div className="footer-body relative bottom-0">
          <div className="footer-logo">
          <a href="/" ><Logo id="footer-logo-id"></Logo></a>  
          </div>
          <div className="footer-actions">
            <a href="/about-us"> About Us</a>
            <a href="/investment"> Investment</a>
            <a href="leadership"> Leadership</a>
            <a href="contact-us"> Contact</a>
          </div>
        </div>

        <div className="line-seperator"></div>
        <div className="footer-subactions">
          <a href="/privacy"> Privacy Policy</a>
          <a href="#"> © 2024 Dovehouse Capital Inc. All Rights Reserved.</a>
          <a href="/terms"> Terms and Conditions</a>
        </div>
      </div>
    </div>
  );
}

export default HomeFooter;
