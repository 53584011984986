import React from "react";
import { motion } from "framer-motion"; // Import motion from framer-motion
import "./AboutUs.css";
import Aboutimgurl from "../../assets/about-1.png";
import InvestmentMastery from "./inventorymaster/InvestmentMastery";
import Prosperity from "./Prosperity/Prosperity";
import StrategicFlexibility from "./StrategicFlexibility/StrategicFlexibility";
import Corevalue from "./Prosperity/Corevalue";

const About = () => {
  return (
    <>
      <section className="about-us">
        <div className="about-us-content">
          <motion.h2
            className="section-title"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            ABOUT US
          </motion.h2>
          <motion.h1
            className="main-title main-title-about"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.4 }}
          >
            Dovehouse CAPITAL IS A <br className="br-none" />
            GLOBAL <span className="highlight about-us-m">INVESTMENT</span>
            <br />
            MANAGEMENT FIRM
          </motion.h1>
          <p className="subtitle">
            With offices in Abu Dhabi & London. We were established
            <br />
            in 2024, by Martin Rasmussen & Claus Rosenberg Gotthard.
          </p>
        </div>
      </section>

      <div className="image-container">
        <img
          src={Aboutimgurl}
          alt="Team meeting"
          className="img-fluid"
          data-aos="fade-right"
          data-aos-duration="300"
          data-aos-easing="ease-in-sine"
        />
      </div>

      <InvestmentMastery />
      <Corevalue />
      <Prosperity />
      <StrategicFlexibility />
    </>
  );
};

export default About;
