import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Drawer from "react-modern-drawer";
import "./App.css";
import "react-modern-drawer/dist/index.css";
import Home from "./components/Home/Home";
import HomeMobile from "./components/Home/HomeMobile";
import About from "./components/About/About";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import SideMenu from "./components/SideMenu/SideMenu";
import InvesmentLeadership from "./components/InvesmentPage/InvesmentLeadership";
import AOS from "aos";
import "aos/dist/aos.css";
import LeadershipComponent from "components/Leadershippage/LeadershipComponent";
import ContactUs from "components/Contactuspage/ContactUs";
import Privacy from "components/Privacypage/Privacy";
import Terms from "components/termsandCondition/Privacy";
import useWindowDimensions from "components/utils/useWindowDimensions";

const sideMenu = [
  { name: "HOME", url: "/" },
  { name: "About Us", url: "/about-us" },
  { name: "Investment", url: "/investment" },
  { name: "Leadership", url: "/leadership" },
  { name: "Contact", url: "/contact-us" },
  // Add other menu items as needed
];

function App() {
  useEffect(() => {
    AOS.init(); // initialize the library
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const { width } = useWindowDimensions(); // Get the window width

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const closeDrawer = () => {
    setIsOpen(false);
  };

  // Determine if current route is the home page
  const isHomePage = location.pathname === "/";

  // Determine if the view is mobile
  const isMobileView = width < 768;

  return (
    <div className="App">
      {!isHomePage && <Header toggleDrawer={toggleDrawer} />}

      <Drawer
        open={isOpen}
        onClose={closeDrawer}
        direction="left"
        className="side-drawer-class" // Add your custom drawer class here
        size={766} // Set your preferred size
      >
        <SideMenu toggleDrawer={toggleDrawer} menuList={sideMenu} />
      </Drawer>

      <Routes>
        <Route
          path="/"
          element={
            isMobileView ? (
              <HomeMobile toggleDrawer={toggleDrawer} />
            ) : (
              <Home toggleDrawer={toggleDrawer} />
            )
          }
        />
        <Route path="/about-us" element={<About />} />
        <Route path="/investment" element={<InvesmentLeadership />} />
        <Route path="/leadership" element={<LeadershipComponent />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        {/* Add more routes for other components */}
      </Routes>

      {!isHomePage && <Footer />}
    </div>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
